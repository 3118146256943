<template>
  <div class="goods">
    <div class="goods-list">
      <div class="spec" v-if="isShow">
        <div style="display: flex">
          <DetailGoodsImg :picArr="picArr" />

          <div style="margin-left: 46px; width: 42rem" class="divFix">
            <div class="goods-text-t">
              <div style="display: flex;justify-content: space-between;align-items: center">
                <p class="g-name">{{ info.productName }}</p>
                <div class="g-service"  @click="toServiceSupport">
                  <span class="g-span">服务介绍</span>
                  <img   src="../img/question.png" alt="">
                </div>
              </div>
              <p class="g-desc">
                {{ info.described }}
              </p>
            </div>

            <div
              class="coupon"
              @click="emitter.emit('couponPopup')"
              :key="info.productName"
              v-track="{event:'领优惠券',page:info.productName+'的增值服务详情'}"
              v-if="couponList.length"
            >
              <div class="coupon-left">
                <template v-for="(item, index) in couponList">
                  <div class="coupon-li" v-if="index < 3">
                    满 {{ item.amountOver }} 减 {{ item.amount }}
                  </div>
                </template>
              </div>
              <div class="coupon-right">
                <span>领优惠券</span>
                <img src="../img/right.png" alt="" />
              </div>
            </div>
            <div class="commodity-tab">
              <div class="tags">
                <div class="dd">
                  <a
                    v-for="(item, index) in info.config"
                    :key="index"
                    :class="{ active: item.id === currentIndex }"
                    href="javascript:"
                    @click="changeSelect(item)"
                    >{{ item.configName }}</a
                  >
                </div>
              </div>
            </div>
            <div class="tabs-tab-two"></div>

            <div class="g-price-btn">
              <!-- 配置即时价格 -->
              <div class="g-price">
                <p><span class="configuration">价格</span></p>
                <p>
                  <span style="line-height: 20px">{{ currentPrice }}</span>
                </p>
              </div>
              <!-- 立即订购 -->
              <div class="box-btn">
                <el-button class="box-btn-l" @click="ToSetting"
                  >立即购买
                </el-button>
                <div class="box-btn-2" @click="onLine" :key="info.productName"  v-track="{event:'咨询客服',page:info.productName+'的增值服务详情'}">咨询客服</div>
              </div>
              <!-- 正品货源 -->
              <div class="order-list" id="unsite1">
                <ul v-for="(item, index) in order" :key="item.id + index">
                  <li>
                    <img src="../../../assets/banner/gouxuan.png" alt="" />
                    <span>{{ item.text }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="goods-tabs-1" id="detailSite">
          <el-tabs v-model="selectTab" type="card">
            <el-tab-pane label="商品详情" name="detail2"></el-tab-pane>
          </el-tabs>
          <div>
            <div id="detail2" v-if="commodityImg" class="scrollClass">
              <DetailsCommodity :commodityImg="commodityImg"></DetailsCommodity>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="app-header-sticky"
      v-if="scrollTop > swarpSite"
      :class="{ show: scrollTop > swarpSite }"
    >
      <div v-if="scrollTop > swarpSite"></div>
      <div class="container-btn">
        <div class="container-left">
          <span
            >{{ info.productName
            }}{{ currentName }}</span
          >
        </div>
        <div class="container-middle">
          <div
            v-if="commodityImg"
            class="container-middle-1"
            @click="topsenceSwtich('detail2')"
            :class="{ active: selectTab === 'detail2' }"
          >
            商品详情
          </div>
        </div>
        <div class="container-right">
          <p>价格:</p>
          <p>
            <span style="font-size: 14px; padding-right: 3px">￥</span>
            <span>{{ currentPrice }}</span>
          </p>
          <div class="r-btn">
            <el-button @click="ToSetting">立即订购</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CouponPopup  :productName="info.productName" is-service :key="info.productName"/>
</template>

<script setup>
import DetailGoodsImg from "./detailGoodsImg.vue";
import CouponPopup from "@/views/detailsGoods/components/CouponPopup.vue";
import emitter from "@/utils/eventBus";
import { useRouter } from "vue-router";
import { addedDetail } from "@/api/incrementService/index";
import { couponQueryList } from "@/api/coupon";
import { onMounted, ref } from "vue";
import ossPath from "@/global-variable";
import DetailsCommodity from "@/views/detailsGoods/components/DetailsCommodity.vue";
import useScrollTop from "@/hooks/useScrollTops";

const scrollTop = useScrollTop();
const swarpSite = ref();

const props = defineProps({
  id: {
    type: [String, Number],
  },
});

const isShow = ref(false);
const isShowBottom = ref(false);
//图片展示
const picArr = ref([]);
const info = ref({});
const currentIndex = ref(0);
const currentPrice = ref(0);
const currentName = ref("");
const selectTab = ref("detail2");
const commodityImg = ref();
const router = useRouter();

const loadTopSite = () => {
  setTimeout(function () {
    const detailSite = document.getElementById("detailSite");
    swarpSite.value = detailSite.offsetTop - 200;
  }, 500);
};
// 点击悬浮标签
const topsenceSwtich = (index) => {
  //document.getElementById('detailSite1').scrollIntoView()
  document.documentElement.scrollTop =
    document.getElementById(`${index}`).offsetTop - 200;
};

onMounted(() => {
  loadTopSite();
  getCouponList();
  addedDetail(props.id).then((res) => {
    let data = res.data;
    let arr = ["picture", "publicizePicture", "detailPicture"];
    Object.keys(data).forEach((v) => {
      if (arr.includes(v)) {
        data[v] = data[v]
          .split(",")
          .map((o) => {
            return ossPath.ossPath + "/goods" + o;
          })
          .join(",");
      }
      if (v === "config") {
        data.config.forEach((v, index) => {
          v.id = index;
        });
      }
    });
    info.value = data;
    data.publicizePicture.split(",").forEach((v) => {
      picArr.value.push(v);
    });
    commodityImg.value = data.detailPicture.split(",").map((v) => {
      return {
        img: v,
      };
    });

    currentPrice.value = data.config[0].price;
    currentName.value = data.config[0].configName;
    localStorage.setItem('productName',data.productName)
    isShow.value = true;
  });
});

//咨询客服
const onLine = () => {
  emitter.emit("onlineClient");
};

//优惠券列表
const couponList = ref([]);
// 查询优惠券
const getCouponList = () => {
  couponQueryList()
    .then((res) => {
      if (res.code == 200) {
        couponList.value = res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

function ToSetting() {
  if (!localStorage.getItem("token")){
    emitter.emit('openLogin',{ openLogin:  true})
    return
  }
  router.push({
    path: "/configAddServiceSetting",
    query: {
      id: props.id,
      settingName: currentName.value,
    },
  });
}

//切换选中
function changeSelect(item) {
  currentIndex.value = item.id;
  currentPrice.value = item.price;
  currentName.value = item.configName;
}

const toServiceSupport = () => {
  router.push({
    path: "/serviceSupport",
    query: {
      introduce: info.value.introduce,
    },
  });
};

const order = ref([
  {
    id: 1,
    text: "精选正品货源",
  },
  {
    id: 2,
    text: "24H顺丰发货",
  },
  {
    id: 3,
    text: "7天无理由退换",
  },
  {
    id: 4,
    text: "整机3年质保",
  },
]);
</script>

<style scoped lang="less">
.divFix{
  display: flex;
  flex-direction: column;
}
.goods {
  width: 1920px;
  background: #fff;
  margin: 0 auto;

  .app-header-sticky {
    width: 1920px;
    height: 64px;
    position: fixed;
    left: 50%;
    top: 71px;
    z-index: 99;
    background: #ffffff;
    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transform: translateX(-50%);

    &.show {
      //transform: none;
      opacity: 1;
      transition: all 1s ease;
    }

    .container-btn {
      display: flex;
      justify-content: space-around;
      background: #ffffff;
      height: 64px;
      line-height: 64px;

      .container-left {
        display: flex;
        margin-left: -46px;

        span {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin-left: 15px;
          width: 277px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .container-middle {
        display: flex;
        margin-left: -630px;

        .container-middle-1 {
          cursor: pointer;
          width: 60px;
          height: 64px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-right: 20px;

          &.active {
            color: #ce1200;
            border-bottom: 2px solid #ce1200;
          }

          &:hover {
            color: #ce1200;
            border-bottom: 2px solid #ce1200;
          }
        }
      }

      .container-right {
        display: flex;
        align-items: center;
        margin-left: -670px;

        p:nth-child(1) {
          width: 50px;
          height: 15px;
          line-height: 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ce1200;
        }

        p:nth-child(2) {
          padding-left: 11px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ce1200;
        }

        .r-btn {
          padding-left: 23px;
          margin-bottom: 8px;

            .el-button {
            width: 100px;
            height: 36px;
            background: #ce1200;
            border-radius: 18px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            border: #ce1200ff;
            border-color: #ce1200ff;
          }
        }
      }
    }
  }

  .goods-list {
    width: 1420px;
    margin: 0 auto;
    // min-height: 600px;
    background: #fff;
    margin-top: 75px;

    // display: flex;
    .media {
      width: 520px;
      padding: 30px 50px;
    }

    .spec {
      flex: 1;
      padding: 30px 0px 30px 65px;

      .coupon {
        display: flex;
        justify-content: space-between;
        margin-left: 13px;
        height: 30px;
        width: 638px;
        cursor: pointer;

        .coupon-left {
          display: flex;

          .coupon-li {
            margin-right: 10px;
            background-image: url(../img/img.png);
            background-size: cover;
            //width: 118px;
            width: 130px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #ce1200;
          }
        }

        .coupon-right {
          span {
            margin-right: 6px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #ce1200;
          }

          img {
            width: 8px;
            height: 12px;
          }
        }
      }
    }
  }
}

.goods-text-t {
  margin-bottom: 32px;
  margin-left: 13px;

  .g-name {
    font-size: 17px;
    font-weight: bold;
    color: #111111;
  }
  .g-service{
    margin-right: 20px;
    width: 200px;
    text-align: right;
    box-sizing: border-box;
    cursor: pointer;
    color: #999999;
    font-size: 14px;
  }
  .g-span{
    vertical-align: middle;
    margin-right: 2px;
  }

  .g-desc {
    font-size: 13px;
    font-weight: 400;
    color: #999;
    margin-top: 10px;
  }
  .g-span:hover{
    color: #ce1200;
  }

  .service-button {
    //margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

.commodity-tab {
  width: 672px;
  margin-top: 16px;

  .tags {
    flex: 1;
    display: flex;

    .dt {
      font-weight: bold;
      width: 100px;
      text-align: right;
      line-height: 42px;
    }

    .dd {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      > a {
        height: 44px;
        margin-left: 12px;
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid #e4e4e4;
        color: #555;
        text-align: center;
        line-height: 40px;
        padding: 0 20px;

        &:hover {
          border-color: #ce1200;
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }

        &.active {
          border-color: #ce1200;
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }
      }
    }
  }
}

.price {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-end;

  .bottomPrice {
    .service-btn {
      height: 50px;
      padding: 10px 20px;
      box-sizing: border-box;
      color: black;
      font-weight: bold;
      font-size: 20px;
      background-color: gainsboro;
    }
  }
}

.box-btn {
  width: 100%;
  display: flex;
  margin-top: 32px;
  margin-bottom: 10px;

  .box-btn-l {
    width: 340px;
    height: 56px;
    background: #ce1200;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    margin-top: 2px;
    border: none;
    margin-right: 11px;
  }

  .box-btn-2 {
    width: 332px;
    height: 54px;
    border-radius: 5px;
    border: 1px solid #ce1200;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #ce1200;
    cursor: pointer;
    text-align: center;
    line-height: 54px;

    margin-top: 3px;
  }
}

.goods-tabs-1 {
  width: 1230px;
  margin-top: 80px;

  .el-tabs__active-bar {
    height: 0;
  }
}

.goods-text-t {
  margin-bottom: 32px;
  margin-left: 13px;

  .g-name {
    font-size: 17px;
    font-weight: bold;
    color: #111111;
  }

  .g-desc {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
  }

  .g-price {
    margin-top: 36px;
    position: relative;

    span:nth-child(1) {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 18px;
      color: #ce1200;
      padding-right: 5px;
    }

    span:nth-child(2) {
      font-weight: bold;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ce1200;
    }

    .g-price-1 {
      position: absolute;
      left: 530px;
      top: 9px;
      cursor: pointer;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 15px;
      color: #ce1200;
    }

    .g-price-2 {
      width: 12px;
      position: absolute;
      left: 626px;
      top: 14px;
      cursor: pointer;
    }
  }
}

.g-price-btn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .g-price {
    display: flex;
    margin-top: 32px;

    .configuration {
      display: inline-block;
      width: 136px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin: auto;
      margin-right: 57px;
      font-size: 14px;
      font-weight: 400;
      color: #555555;
      background-color: #f2f2f2;
    }

    p:nth-child(2) {
      margin-top: 10px;

      span:nth-child(1) {
        &::before {
          content: "¥";
          font-size: 14px;
          margin-right: 5px;
        }

        &:nth-child(1) {
          color: #ce1200;
          margin-right: 10px;
          font-size: 22px;
          font-weight: bold;
        }
      }

      &:nth-child(2) {
        font-size: 11px;
        font-weight: 400;
        color: #555555;

        b {
          color: #ce1200;
        }
      }
    }
  }

  .box-btn {
    width: 100%;
    display: flex;
    margin-top: 32px;

    .box-btn-l {
      width: 340px;
      height: 56px;
      background: #ce1200;
      border-radius: 5px;
      color: #fff;
      font-size: 15px;
      margin-top: 2px;
      border: none;
    }

    .box-btn-2 {
      width: 332px;
      height: 54px;
      border-radius: 5px;
      border: 1px solid #ce1200;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 15px;
      color: #ce1200;
      cursor: pointer;
      text-align: center;
      line-height: 54px;
      margin-right: 11px;
      margin-top: 3px;
    }

    .box-btn-r {
      width: 340px;
      height: 60px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .order-t {
    margin-top: 23px;

    span:nth-child(1) {
      margin-left: 4px;
    }

    span:nth-child(2) {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      margin-left: 6px;
    }
  }

  .order-list {
    display: flex;
    margin-top: 22px;

    li {
      margin-right: 30px;

      img {
        width: 16px;
        height: 16px;
        margin-bottom: 3px;
        margin-left: 5px;
      }

      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left: 5px;
      }
    }
  }
}

.serviceSetting {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 50px 0;
  box-sizing: border-box;
}

.goods-tabs-1 {
  width: 1230px;
  margin-top: 80px;

  .el-tabs__active-bar {
    height: 0;
  }
}

 .el-tabs__nav-wrap::after {
  background-color: transparent;
}

 .el-tabs__header {
  margin: 0;
  background: #f2f2f2;
}

 .el-tabs__content {
  padding: 0;
}

 .el-tabs__item {
  font-size: 17px;
  font-weight: 400;
}

.el-tabs__item.is-active {
  color: #ffffff;
  background: #ce1200;
}

 .el-tabs__item:hover {
  color: #ce1200;
}

 .el-tabs__item:hover {
  background: #ce1200;
  color: #fff !important;
}
</style>
